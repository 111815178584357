<template>
  <div class="page-root tools-qianru-root">
    <div class="tools-banner-wrapper">
      <div class="tools-banner">
        <div class="text">
          <div class="en">VEHICLE TRACK</div>
          <div class="zh">二手车迁移准入标准城市查询</div>
          <div class="en">QUERY</div>
          <div class="line"></div>
        </div>
        <div class="box"></div>
        <img src="@/assets/pexels-jae-park-3802512@2x.png" />
      </div>
    </div>
    <div class="tabs-wrapper">
      <div class="title">迁入标准查询</div>
      <div class="list-bar">
        <div class="list">
          <el-cascader
            :append-to-body="false"
            clearable
            :props="props1"
            v-model="value1"
            :placeholder="cityName || '请选择城市'"
          ></el-cascader>
        </div>
        <div class="butt">
          <div @click="search">开始查询</div>
        </div>
      </div>
      <div class="item">
        <div class="label"><span></span>车管所</div>
        <div>{{ obj.local }}</div>
      </div>
      <div class="item">
        <div class="label"><span></span>排放标准</div>
        <div>{{ obj.fueltype }}</div>
      </div>
      <div class="item">
        <div class="label"><span></span>标准细节</div>
        <div>{{ obj.context }}</div>
      </div>
      <div class="tips">（ 注：建议购车前致电当地车管所询问具体迁入准则，以当地车管所的信息为准。)</div>
    </div>
    <div class="hot-city">
      <div class="title">热门城市</div>
      <div class="item">
        <div v-for="(item, i) in hotList" :key="i" @click="toHot(item)">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import './styles/mobile.scss';
import './styles/pc/pc.scss';
import { get } from '@/utils/axios';
export default {
  methods: {
    search() {
      if (!this.value1.length) {
        this.$message({
          message: '城市未选择 ！',
          type: 'warning',
        });
      } else {
        get(`/system/standard/list?city=${this.value1[1]}`).then((res) => {
          if (res.code == 200) {
            this.obj = res.rows[0];
          } else {
            this.$message.error(data.msg);
          }
        });
      }
    },
    toHot(item) {
      this.cityName = item.name;
      this.value1 = [];
      get(`/system/standard/list?city=${item.code}`).then((res) => {
        if (res.code == 200) {
          this.obj = res.rows[0];
        } else {
          this.$message.error(data.msg);
        }
      });
    },
  },
  data() {
    return {
      hotList: [
        {
          name: '北京',
          code: '110100',
        },
        {
          name: '上海',
          code: '310100',
        },
        {
          name: '广州',
          code: '440100',
        },
        {
          name: '深圳',
          code: '440300',
        },
        {
          name: '大连',
          code: '210200',
        },
        {
          name: '沈阳',
          code: '210100',
        },
        {
          name: '郑州',
          code: '410100',
        },
        {
          name: '成都',
          code: '510100',
        },
        {
          name: '长春',
          code: '220100',
        },
        {
          name: '济南',
          code: '370100',
        },
        {
          name: '宁波',
          code: '330200',
        },
        {
          name: '青岛',
          code: '370200',
        },
        {
          name: '哈尔滨',
          code: '370200',
        },
        {
          name: '石家庄',
          code: '130100',
        },
        {
          name: '南宁',
          code: '450100',
        },
        {
          name: '昆明',
          code: '530100',
        },
      ],
      cityName: '',
      value1: [],
      obj: {},
      props1: {
        lazy: true,
        lazyLoad(node, resolve) {
          const { level, value } = node;
          let url = '/system/region/list';
          if (level == 1) {
            url = `/system/region/list?parentId=${value}`;
          }
          get(url).then((res) => {
            let nodes = res.rows.map((item) => {
              return {
                value: item.id,
                label: item.name,
                leaf: level >= 1,
              };
            });
            resolve(nodes);
          });
        },
      },
    };
  },
};
</script>
